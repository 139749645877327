<template>
  <div class="overlay" :style="{ display: overlay ? 'block' : 'none' }"></div>
  <main
    class="page"
    :class="{ 'menu-open': mainMenuStatus, 'menu-hidden': hideMainMenu }"
  >
    <section class="menu-wrapper">
      <main-navigation />
    </section>
    <section class="main-content">
      <div
        :class="
          `screens-wrapper${isFullWidthScreen ? ' full-width-screen' : ''}`
        "
      >
        <router-view />
      </div>
    </section>
    <button
      type="button"
      class="menu-close-overlay"
      @click="setMenuStatus(false)"
    >
      &nbsp;
    </button>
    <div
      class="notification push-notification"
      v-if="pushNotification && pushNotification.title && pushNotification.body"
    >
      <div class="notification-info push-notification-info">
        <span>{{ pushNotification.title }}</span
        ><br /><br /><br />
        <p v-html="pushNotification.body"></p>
      </div>
      <button
        class="notification-button push-notification-button"
        @click="closePushNotification()"
      >
        {{ displayLabelName("global.buttons.ok") }}
      </button>
    </div>
  </main>
</template>

<script>
import MainNavigation from "@/components/global/MainNavigation";
import { mapActions, mapState } from "vuex";
import { preferenceMenuModules } from "@/services/consts/modules/preference-menu-modules";
// import { isWebview } from "@/services/is-web-view";
// import { messaging, requestFCMToken, onMessage } from "@/services/firebase";

export default {
  name: "LoggedInLayout",
  data() {
    return {
      channel: null,
      declinedDeclarationSubject: null,
      declinedDeclarationResource: null,
      declinedDeclarationPath: null,
      declinedDeclarationDate: null,
      declinedDeclarationTime: null,
      pushNotification: null,
      overlay: false,
      messaging: null,
      requestFCMToken: null,
      onMessage: null,
    };
  },
  async created() {
    // if (!isWebview()) {
    // Request permission to send notifications
    // if (!isWebview()) {
    // Request permission to send notifications
    //     Notification.requestPermission().then(async (permission) => {
    //       if (permission === "granted") {
    //         try {
    //           const module = await import("@/services/firebase");
    //           this.requestFCMToken = module.requestFCMToken;
    //           this.messaging = module.messaging;
    //           this.onMessage = module.onMessage;
    //           const token = await this.requestFCMToken();
    //           if (token) {
    //             // console.log("FCM Token:", token);
    //             // You can send this token to your server to save it for later use
    //           } else {
    //             console.log(
    //               "No registration token available. Request permission to generate one."
    //             );
    //           }
    //           // Handle incoming messages when the app is in the foreground
    //           this.onMessage(this.messaging, (payload) => {
    //             console.log("Message received. ", payload);
    //             this.handleNotification(payload);
    //           });
    //         } catch (error) {
    //           console.error("Unable to get permission to notify.", error);
    //         }
    //       }
    //     });
    //   }
    // }
  },
  computed: {
    ...mapState("global", ["mainMenuStatus", "isFullWidthScreen", "showMenu"]),
    hideMainMenu() {
      return (
        !this.showMenu &&
        preferenceMenuModules.find(
          (el) => el.pathName === this.$route.matched[0].name
        )
      );
    },
  },
  methods: {
    ...mapActions("global", ["setMenuStatus"]),
    handleNotification(payload) {
      // Process and display the notification
      // const notificationTitle = payload.notification.title;
      // const notificationOptions = {
      //   body: payload.notification.body,
      //   icon: payload.notification.icon,
      // };

      // new Notification(notificationTitle, notificationOptions);

      this.pushNotification = {
        title: payload.notification.title,
        body: payload.notification.body,
      };
      document.title = "(1) " + document.title;
      this.overlay = true;
    },
    closePushNotification() {
      this.pushNotification = false;
      document.title = document.title.replace("(1) ", "");
      this.overlay = false;
    },
  },
  components: {
    MainNavigation,
  },
};
</script>
<style>
.notification {
  width: 300px;
  min-height: 200px;
  background-color: red;
  position: absolute;
  top: 10px;
  right: 10px;
  color: white;
  z-index: 10000 !important;
  border-radius: 10px;
  box-shadow: 0 0.1875rem 0.375rem rgba(234, 232, 232, 0.16);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.notification-info {
  flex: 1;
  padding: 10px;
  padding-top: 17px;
  width: 100%;
}

.notification-info span {
  margin-bottom: 10px;
}

.notification-info p {
  margin-bottom: 5px;
}

.notification-button {
  width: 100%;
  border-top: 1px solid white;
  height: 50px;
  color: white;
  font-size: 16px;
}

.push-notification {
  background-color: white;
  color: black;
  border: 1px solid rgb(198, 198, 198);
  position: fixed; /* Fixed positioning */
  top: 50%; /* Center vertically */
  left: 50%; /* Center horizontally */
  transform: translate(
    -50%,
    -50%
  ); /* Offset by 50% of its own width and height */
  z-index: 500000 !important;
  min-width: 300px;
  word-wrap: break-word;
}

.push-notification-button {
  color: black;
  border-top: 1px solid rgb(198, 198, 198);
}

.push-notification-info span {
  margin-bottom: 10px;
  font-weight: 500;
}

.push-notification-info p {
  margin-bottom: 5px;
}

.overlay {
  position: fixed; /* Stay in place */
  top: 0; /* Stay in place */
  left: 0; /* Stay in place */
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  background-color: rgba(0, 0, 0, 0.5); /* Black background with opacity */
  z-index: 100000; /* Sit on top */
  display: none;
}
</style>
